<template>
  <div class="ApprovalTag">
    <!-- 任务审批 -->
    <van-nav-bar
      :title="'任务审批'"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content">
      <van-search
        v-model="searchVal"
        shape="round"
        :placeholder="'搜索'"
        @search="onSearch"
      />
      <van-tabs
        v-model="activeName"
        color="#2B8DF0"
        title-active-color="#2B8DF0"
        @change="changeAct"
      >
        <van-tab :title="'待审批' + sureNum" name="Sure"></van-tab>
        <van-tab :title="'不同意' + noNum" name="No"></van-tab>
        <van-tab :title="'已审批' + checkNum" name="Check"></van-tab>
      </van-tabs>

      <van-pull-refresh
        :class="activeName == 'Sure' ? 'Check' : ''"
        v-model="isLoadingAwait"
        @refresh="onRefreshAwait"
      >
        <van-list
          v-model="loadingAwait"
          :finished="finishedAwait"
          :finished-text="$t('module.noMore')"
          @load="onLoadAwait"
        >
          <div
            class="zb_group"
            v-for="(item, index) in assessList"
            :key="index"
          >
            <div class="zb_group">
              <van-cell
                class="ass_cell"
                size="large"
                :title="item.taskname"
                :label="`${item.empname}-${item.deptname}`"
                @click="goToApply(item)"
              >
                <template slot="label">
                  {{ item.empname }}-{{ item.deptname }}<br />
                  周期：{{ item.khtimename }}
                </template>
                <template>
                  <div style="color:red">权重：{{ item.taskper }}</div>
                  <div>{{ item.reqdate }}</div>
                  <div v-if="activeName == 'No'" style="color:red">
                    不同意原因：{{ item.checkstatus }}
                  </div>
                </template>
              </van-cell>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {
  getMyForCheckGsTaskList,
  getMyForDeAgreeGsTaskList,
  getMyHasCheckGsTaskList
} from '@api/wxjx.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      activeName: 'Sure',
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      sureList: [],
      checkList: [],
      noList: [],
      searchVal: '',
      sureNum: '',
      noNum: '',
      checkNum: ''
    }
  },
  watch: {
    searchVal(n) {
      this.onSearch()
    }
  },
  created() {},
  methods: {
    onSearch() {
      if (this.searchVal) {
        this.assessList = this.assessList.filter(
          item =>
            item.empname.indexOf(this.searchVal) > -1 ||
            item.deptname.indexOf(this.searchVal) > -1 ||
            item.taskname.indexOf(this.searchVal) > -1
        )
      } else {
        this.getList()
      }
    },
    onClickLeft() {
      this.$router.push('/home')
    },
    // 9.1.获取待审批的360考核方案列表
    getList() {
      let _this = this
      getMyForCheckGsTaskList({ empid: this.userInfo.empid }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          _this.sureNum = ''
          // 无数据
        } else {
          _this.sureNum = '(' + res.data.length + ')'
          _this.sureList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }

        if (_this.activeName == 'Sure')
          _this.assessList = JSON.parse(JSON.stringify(_this.sureList))
      })
      getMyForDeAgreeGsTaskList({ empid: this.userInfo.empid }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          _this.noNum = ''
          // 无数据
        } else {
          _this.noNum = '(' + res.data.length + ')'
          _this.noList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
        if (_this.activeName == 'No')
          _this.assessList = JSON.parse(JSON.stringify(_this.noList))
      })
      getMyHasCheckGsTaskList({ empid: this.userInfo.empid }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          _this.checkNum = ''
          // 无数据
        } else {
          _this.checkNum = '(' + res.data.length + ')'
          _this.checkList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
        if (_this.activeName == 'Check')
          _this.assessList = JSON.parse(JSON.stringify(_this.checkList))
      })
    },
    onLoadAwait() {
      // 异步更新数据
      if (this.isLoadingAwait) {
        this.assessList = []
        this.isLoadingAwait = false
      }
      this.getList()
    },
    onRefreshAwait() {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      // Toast('刷新成功')
    },
    // tab切换事件
    changeAct(name, title) {
      this.onRefreshAwait()
    },
    // 发起指标申请
    goToApply(item) {
      let isSure = true
      if (this.activeName == 'Sure') {
        this.$router.push({
          path: '/exaTaskApply',
          query: {
            title: item.taskname,
            autoid: item.autoid,
            from: '/exaTask'
          }
        })
      } else if (this.activeName == 'Check') {
        this.$router.push({
          path: '/queTaskApply',
          query: {
            title: item.taskname,
            autoid: item.autoid,
            from: '/exaTask'
          }
        })
      } else if (this.activeName == 'No') {
        this.$router.push({
          path: '/ordTask',
          query: {
            type: '1',
            title: item.taskname,
            autoid: item.autoid,
            from: '/exaTask'
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ApprovalTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
    }
    .KPITagRefresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 220px);
    }

    .zb_group {
      // .van-cell::after {
      //   position: absolute;
      //   box-sizing: border-box;
      //   content: " ";
      //   pointer-events: none;
      //   right: 0.42667rem;
      //   bottom: 0;
      //   left: 0.42667rem;
      //   border-bottom: 2px solid #ebedf0;
      //   -webkit-transform: scaleY(0.5);
      //   transform: scaleY(0.5);
      // }
      .ass_cell {
        margin-bottom: 10px;
        .van-cell__title {
          flex: 1;
          line-height: 54px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #2b8df0;
        }
        .van-cell__label {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
        }
        .van-cell__value {
          position: relative;
          color: #333333;
          padding-bottom: 60px;
          .task_ischeck {
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .custom-score {
            font-size: 48px;
            font-family: DIN;
            font-weight: bold;
            color: #2b8df0;
          }
        }
      }
    }
  }
}
</style>
